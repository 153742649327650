.modal {
  display: flex;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.5s ease;
  justify-content: center;
  align-items: flex-start;
  padding-top: 30px;
}

.modalContent {
  background-color: #fff;
  padding: 40px;
  border-radius: 12px;
  width: 100%;
  margin: 20px;
  min-width: 200px;
  max-width: 700px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  transition: transform 0.4s ease, opacity 0.4s ease;
  opacity: 0;
  transform: translateY(+50px);
  position: relative;
  margin-bottom: 100px;
}

.modal.show {
  background-color: rgba(0, 0, 0, 0.8);
}

.modalContent.show {
  opacity: 1;
  transform: translateY(0px);
}

.close {
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 2rem;
  font-weight: 300;
  cursor: pointer;
  background: none;
  border: none;
  color: #333;
  transition: color 0.2s ease;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.close:hover,
.close:focus {
  color: #000;
  background-color: rgba(0, 0, 0, 0.05);
  text-decoration: none;
}

.title_modal {
  font-size: 2rem;
  margin-bottom: 30px;
  font-weight: 700;
  white-space: normal;
  word-wrap: break-word;
  color: #222;
  line-height: 1.3;
}

.content_modal {
  white-space: normal;
  word-wrap: break-word;
  line-height: 1.6;
  color: #333;
  font-size: 1.1rem;
}

.contentLine {
  margin-bottom: 1.2em;
}

.contentLine:last-child {
  margin-bottom: 0;
}

.contentLink {
  color: #000;
  text-decoration: underline;
  transition: color 0.2s ease;
}

.contentLink:hover {
  color: #555;
}

@media (max-width: 768px) {
  .modalContent {
    padding: 30px 25px;
    margin: 15px;
  }
  
  .title_modal {
    font-size: 1.7rem;
    margin-bottom: 20px;
  }
  
  .content_modal {
    font-size: 1rem;
  }
}