.resumePage {
  padding: 30px 20px;
  max-width: 550px;
  margin: auto;
}

.heading {
  font-size: 2rem;
  color: #222;
  margin-bottom: 20px;
  font-weight: 700;
  position: relative;
}

.heading:after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 40px;
  height: 3px;
  background-color: #333;
}

.section {
  margin-bottom: 60px;
}

.list {
  list-style-type: none;
  padding: 0;
}

.listItem {
  background-color: #ffffff;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  transition: all 0.3s ease;
  border: 1px solid #e3e3e3;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.03);
}

.listItem:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08);
  transform: translateY(-3px);
}

.listItem strong {
  font-size: 1.1rem;
  color: #222;
  display: inline-block;
  margin-bottom: 5px;
}

.link {
  color: #000;
  text-decoration: none;
  position: relative;
  display: inline-block;
  font-weight: 500;
}

.link::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #000;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease;
}

.link:hover::before {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.link:hover {
  color: #333;
}
