.designContent {
    padding: 30px 20px;
    max-width: 550px;
    margin: auto;
}

.lineAndItem {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.line {
    flex: 0 0 25px;
    height: 3px;
    background-color: #ddd !important;
}

.iconY{
    color: rgb(187, 26, 26);
    transition: transform 0.2s ease;
}

.iconB{
    color: rgb(53, 26, 187);
    transition: transform 0.2s ease;
}

.iconD{
    color: rgb(209, 7, 125);
    transition: transform 0.2s ease;
}

.iconI{
    color: rgb(209, 7, 101);
    transition: transform 0.2s ease;
}

.iconP{
    color: rgb(30, 30, 30);
    transition: transform 0.2s ease;
}

.iconY:hover, .iconB:hover, .iconD:hover, .iconI:hover, .iconP:hover {
    transform: scale(1.15);
}

.listItem {
    display: flex;
    background-color: #ffffff;
    padding: 20px;
    margin-bottom: 25px;
    border-radius: 8px;
    border: 1px solid #e3e3e3;
    flex: 1;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.03);
    transition: all 0.3s ease;
}

.link {
    background-color: rgb(255, 255, 255);
    align-self: stretch;
    margin-bottom: 24px;
    border-radius: 6px;
    margin-left: 10px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 20px;
    border: 1px solid rgb(220, 220, 220);
    transition: all 0.3s ease;
}

.link:hover {
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.1);
    transform: scale(1.05);
    cursor: pointer;
}

.heading {
    font-size: 2rem;
    color: #222;
    margin-bottom: 30px;
    font-weight: 700;
    position: relative;
}

.heading:after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 40px;
    height: 3px;
    background-color: #333;
}

.section {
    margin-bottom: 60px;
}

.list {
    list-style-type: none;
    border-left: 3px solid #ddd;
    margin-left: 12px;
}

.video {
    border-radius: 8px;
    border: none;
    margin-bottom: 15px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}