.header {
  background-color: #1c1c1c;
  color: white;
  padding: 15px;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.navigation {
  padding: 0;
  display: flex;
  gap: 15px;
  align-items: center;
  font-size: 18px;
}

.navLinkActive {
  text-decoration: none;
  color: white;
  padding: 8px 12px;
  border: 0;
  position: relative;
  font-weight: 500;
}

.navLinkActive:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  height: 2px;
  background-color: white;
}

.navLink {
  color: rgb(150, 150, 150);
  text-decoration: none;
  padding: 8px 12px;
  border: 0;
  transition: color 0.2s ease;
}

.navLink:hover {
  color: rgb(200, 200, 200);
}

body {
  margin-top: 70px;
  background-color: #fafafa;
  color: #333;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
