.quiz-container {
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 50px;
    padding: 30px;
    border: 1px solid rgb(220, 220, 220);
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.question-count {
    margin-bottom: 15px;
    color: #666;
    font-size: 1.0rem;
    font-weight: 500;
}

.question-text {
    margin-bottom: 25px;
    font-size: 1.5em;
    font-weight: bold;
    border-radius: 10px;
    color: #222;
    line-height: 1.3;
}

.answer-section button {
    display: block;
    width: 100%;
    padding: 15px 20px;
    margin-top: 12px;
    font-size: 1.1rem;
    color: white;
    background-color: #222;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.answer-section button:hover {
    background-color: #000;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.score-section {
    font-size: 1.2em;
    padding: 25px;
    text-align: left;
    border: 1px solid rgb(220, 220, 220);
    border-left: 5px solid rgb(50, 50, 50);
    border-radius: 10px;
    line-height: 1.6;
    color: #333;
    background-color: #fafafa;
}